import React from 'react';

import { Content } from './styles';

const StrapiContent = ({ content }) => {
  return (
    <Content dangerouslySetInnerHTML={{ __html: content }} />
  );
}

export default StrapiContent;