import React from 'react';

import { 
  Wrapper, 
  GridCard, 
  GridTitle, 
  GridDescription,
} from './styles';

const KnowledgeDoc = ({ title, content }) => {
  return (
    <Wrapper id="doc">
      <GridCard>
        <GridTitle>{title}</GridTitle>
        <GridDescription>{content}</GridDescription>
      </GridCard>
    </Wrapper>
  );
}

export default KnowledgeDoc;