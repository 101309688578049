import styled from 'styled-components';
import AniLink from "gatsby-plugin-transition-link/AniLink";

export const Wrapper = styled.div`
  border-top: 1px solid #eee;
  margin-top: 40px;
`;

export const Title = styled.div`
  margin-top: 20px;
  font-size: 20px;
  font-weight: bold;
  font-family: 'Sora', sans-serif;
  color: #044D75;
  margin-bottom: 20px;
`;

export const RelatedItem = styled.ul`
  display: flex;
  flex-direction: column;
`;

export const Item = styled(AniLink)`
  margin-bottom: 10px;
  text-decoration: none;
  color: #546b81;

  &:hover {
    color: #489be8;
  }
`;
