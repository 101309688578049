import styled from 'styled-components';

export const Content = styled.div`
  * {
    line-height: 24px;
  }

  strong {
    font-family: 'Sora', sans-serif;
    font-weight: bold;
  }
  
  ol, ul, li {
    list-style: square;
    margin-left: 10px;
    font-family: 'Sora', sans-serif;
    color: #294661;
    font-size: 14px;

    &::marker {
      color: #294661;
    }
  }

  blockquote {
    border-left: 3px solid #EE7600;
    padding-left: 10px;
    
    p {
      font-family: 'Sora', sans-serif !important;
      font-size: 14px;
      color: #294661;
    }

    h4, h5 {
      font-style: italic;
      strong {
        color: #294661;
        font-size: 16px;
        font-family: 'Sora', sans-serif;
      }
    }
  }

  p {
    font-family: 'Sora', sans-serif !important;
    color: #294661;
    font-size: 14px;

    a {
      text-decoration: none;
      color: #EE7600;
    }

    blockquote {
      border-left: 3px solid #EE7600;
      padding-left: 10px;
    }
  }
`;