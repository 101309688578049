import styled from 'styled-components';

export const Wrapper = styled.div`
  width: auto;
  height: auto;
  margin-top: 30px;
  padding: 0 150px 0;
  z-index: 0;

  @media (max-width: 1200px) {
    padding: 0 100px 0;
  }

  @media (max-width: 800px) {
    padding: 0 20px 0;
  }

  @media (max-width: 660px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 20px 30px;
  }
`;

export const GridCard = styled.div`
  padding: 26px 30px 30px;
  background: transparent;
  border-left: 1px solid #eee;
  border-bottom: 1px solid #eee;
  border-right: 1px solid #eee;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.04);
  position: relative;
`;

export const GridTitle = styled.h2`
  font-size: 24px;
  font-weight: bold;
  font-family: 'Sora', sans-serif;
  color: #2A5072;
`;

export const GridDescription = styled.div`
  color: #546b81;
  margin: 24px 0;

  > .content {
    padding: 20px 0;

    > p {
      margin: 20px 0;
    }
  }
`;

