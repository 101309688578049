import React from 'react';
import { graphql } from 'gatsby';

import SEO from "../../../components/seo";
import Header from '../../../components/Header';
import KnowledgeCenter from '../../../components/KnowledgeCenter';
import Footer from '../../../components/Footer';
import KnowledgeDoc from '../../../components/KnowledgeDoc';
import GoTop from '../../../components/GoTop';
import GoTopMobile from '../../../components/GoTopMobile';
import StrapiContent from '../../../components/StrapiContent';

import {
  Wrapper,
  Title,
  RelatedItem,
  Item
} from '../../../components/Related/styles';

import GlobalStyles from '../../../styles/GlobalStyles';

const KnowledgeTemplate = ({ data }) => {
  const knowledge = data.strapi.knowledge;

  return (
    <>
      <SEO
        title={`SmartEnvios: ${knowledge.title}`}
      />
      <Header />
      <KnowledgeCenter />

      <KnowledgeDoc
        title={knowledge.title}
        content={
          <>
            <StrapiContent
              content={knowledge.content}
            />

            {knowledge.related.length !== 0
              ? <Wrapper>
                <Title>Veja artigos relacionados</Title>
                <RelatedItem>
                  {knowledge.related.map(related => (
                    <Item
                      key={related.id}
                      cover
                      bg="#fff"
                      to={`/${related.class.slug}/${related.slug}`}
                    >
                      {`- ${related.title}`}
                    </Item>
                  ))}
                </RelatedItem>
              </Wrapper>
              : <div />
            }
          </>
        }
      />

      <Footer />
      <GoTopMobile />
      <GoTop />
      <GlobalStyles />
    </>
  );
};

export default KnowledgeTemplate;

export const query = graphql`
  query KnowledgeTemplate($id: ID!) {
    strapi {
      knowledge(id: $id) {
        title
        content
        id
        related {
          title
          id
          slug
          class {
            slug
          }
        }
      }
    }
  }
`;